import React, { useState, useEffect } from "react";
import PortfolioData from "../../data/portfolio/PortfolioData.json";
import PortfolioItem from "./PortfolioItem";
import { FaSpinner } from "react-icons/fa";

const filters = [
    { id: 1, text: "All" },
    { id: 2, text: "Website" },
    { id: 3, text: "Design" },
    { id: 4, text: "Form" },
    { id: 5, text: "Landing Page" },
];

const PortfolioOne = ({ Column }) => {
    const dataIncrement = 30;
    const allItems = PortfolioData;
    const [dataVisibleCount, setDataVisibleCount] = useState(30);
    const [noMorePost, setNoMorePost] = useState(false);
    const [activeFilter, setActiveFilter] = useState(filters[0].text.toLowerCase());
    const [visibleItems, setVisibleItems] = useState([]);

    useEffect(() => {
        setVisibleItems(allItems.filter((item) => item.id <= dataVisibleCount));
    }, [dataVisibleCount, allItems]);

    const handleChange = (e) => {
        e.preventDefault();
        const filter = e.target.textContent.toLowerCase();
        setActiveFilter(filter);
    
        const filteredItems = filter === filters[0].text.toLowerCase()
          ? allItems.filter((item) => item.id <= dataVisibleCount)
          : allItems.filter((item) => item.category === filter && item.id <= dataVisibleCount);
    
        setVisibleItems(filteredItems);
    };

    const handleLoadMore = (e) => {
        e.preventDefault();
        const newVisibleCount = dataVisibleCount + dataIncrement;
    
        if (newVisibleCount >= allItems.length) {
            setNoMorePost(true);
        }
        setDataVisibleCount(newVisibleCount);
    
        const filteredItems = activeFilter === filters[0].text.toLowerCase()
            ? allItems.filter((item) => item.id <= newVisibleCount)
            : allItems.filter((item) => item.category === activeFilter && item.id <= newVisibleCount);
        setVisibleItems(filteredItems);
    };
    
    return (
        <>
            <div className="row row--15">
                <div className="col-lg-12">
                    <ul className="rwt-portfolio-filter filter-button-default liststyle mb--20">
                        {filters.map((filter) => (
                        <li className="list-item" key={filter.id}>
                            <button
                                onClick={handleChange}
                                className={
                                    filter.text.toLowerCase() === activeFilter ? "current" : " "
                                }
                            >
                                {filter.text}
                            </button>
                        </li>
                        ))}
                    </ul>
                </div>
            </div>

            <div className="row row--15">
                {visibleItems.map((item) => (
                    <div key={item.id} className={Column}>
                        <PortfolioItem portfolio={item} />
                    </div>
                ))}
            </div>

            {/* <div className="row row--15">
                <div className="col-lg-12">
                    <div className="rwt-load-more text-center mt--50">
                        <button
                            className="btn btn-default btn-large btn-icon"
                            onClick={handleLoadMore}
                            disabled={noMorePost}
                        >
                            {noMorePost ? "No Item Here" : (
                            <span>
                                Load More 
                                <span className="icon">
                                    <FaSpinner />
                                </span>
                            </span>
                            )}
                        </button>
                    </div>
                </div>
            </div> */}
        </>
    )
}

export default PortfolioOne;
