import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

function CookieConsent() {
  // State to track if the consent is accepted
  const [isCookieConsentAccepted, setIsCookieConsentAccepted] = useState(false);
  const [showBanner, setShowBanner] = useState(false);

  // Check if consent is already accepted
  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (consent === 'true') {
      setIsCookieConsentAccepted(true);
    } else {
      const timer = setTimeout(() => setShowBanner(true), 3000); // Show after 3 seconds
      return () => clearTimeout(timer); // Cleanup timeout
    }
  }, []);

  // Function to handle the "Accept" button click
  const handleAcceptCookies = () => {
    setIsCookieConsentAccepted(true);
    localStorage.setItem('cookieConsent', 'true');
    setShowBanner(false);
  };

  // Do not render the banner if consent is accepted or not visible
  if (isCookieConsentAccepted || !showBanner) {
    return null;
  }

  return (
    <div style={styles.subcontainer} className='subcontainer' role="dialog" aria-hidden="true">
      <div className='res-cookie' style={styles.container} >
        <p className='cookie-para' >
          We use cookies to optimise and continuously improve our website for individual users like you. By clicking “Accept all”, you accept storing of cookies on your device. Find out more at our <Link to="#">Privacy Policy</Link>
        </p>
        <button onClick={handleAcceptCookies} className='cookie-button' >
          Accept All
        </button>  
      </div>
    </div>
  );
}

const styles = {
  subcontainer: {
    width:'100%',
    margin: ' 0 auto',
    display: 'none',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    width: '100%',
    margin: '0 auto',
    maxWidth: '1200px',
    position: 'fixed',
    bottom: '20px',
    background: 'rgba(0, 0, 0, 0.8)',
    backdropFilter: 'blur(8px)',
    color: 'white',
    padding: '30px 35px',
    borderRadius: '5px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    zIndex: 1000,
  },
};

export default CookieConsent;
