import React, { useState } from 'react'
import { Accordion, Card, Button } from 'react-bootstrap';
import { FaPlus, FaMinus } from "react-icons/fa";

const Data = [
    {
        question : "How long does it take to build a website?",
        content: "The timeline for building a website depends on the project’s scope and complexity. Generally, a basic website may take 1-2 weeks, while more complex websites, such as e-commerce sites or custom applications, can take 3-4 weeks or shorter. We’ll provide a timeline after discussing your project requirements.",
        activeKey: "0",
    },
    {
        question : "Will my website be mobile-friendly and optimized for SEO?",
        content: "Yes, all our websites are designed to be responsive, ensuring they work seamlessly across all devices, including mobile, tablet, and desktop. We also follow best practices for SEO, helping your website rank better in search engine results.",
        activeKey: "1",
    },
    {
        question : "Do you offer website maintenance and support after the site goes live?",
        content: "Yes, we offer ongoing maintenance and support packages to keep your website secure, up-to-date, and running smoothly. Whether you need content updates, security monitoring, or regular backups, we can tailor a support plan to fit your needs.",
        activeKey: "2",
    },
    {
        question : "How does the digital art commission process work?",
        content: "Our process begins with understanding your idea and project requirements. Once we agree on the style, scope, and timeline, we create sketches or initial concepts for your approval before finalizing the artwork. Regular updates keep you informed throughout the project.",
        activeKey: "3",
    },
    {
        question : "What file formats will I receive for the final artwork?",
        content: "We deliver high-resolution artwork in formats such as PNG, JPEG, or PSD. For game assets or animations, additional formats can be provided based on your specific requirements.",
        activeKey: "4",
    },
    {
        question : " Can I request revisions during the project?",
        content: "We deliver high-resolution artwork in formats such as PNG, JPEG, or PSD. For game assets or animations, additional formats can be provided based on your specific requirements.",
        activeKey: "5",
    },
    {
        question : "Do you offer exclusive rights to the artwork?",
        content: "Yes, we offer both personal and commercial licenses. For exclusive rights, additional fees apply depending on the intended use of the artwork.",
        activeKey: "6", 
    },
];

const Data1 = [
    {
        question : "How much does a website cost?",
        content: "Website costs vary depending on design, functionality, and the level of customization needed. After discussing your requirements, we’ll provide a detailed quote that includes all anticipated expenses. Our goal is to deliver a solution that fits within your budget while meeting your business goals.    ",
        activeKey: "0",
    },
    {
        question : "Can you redesign an existing website?",
        content: "Yes, we can redesign and improve your current website to better reflect your brand, update the design, improve functionality, or optimize it for mobile use and SEO. We’ll start by assessing your existing website and discussing your goals for the redesign.",
        activeKey: "1",
    },
    {
        question : "Can you help with digital marketing?",
        content: "Yes, we offer digital marketing services, including SEO, social media management, pay-per-click advertising, and content marketing, to help you drive traffic and grow your online presence. Let us know your goals, and we’ll create a strategy tailored to your needs.",
        activeKey: "2",
    },
    {
        question : "How do you ensure the security of my website?",
        content: "Website security is a top priority. We follow best practices for web security, including SSL installation, secure coding practices, regular updates, and backups. We also offer ongoing security monitoring and maintenance services to protect your website from threats.",
        activeKey: "3",
    },
    {
        question : "Can you design assets for specific art styles?",
        content: "Yes, we adapt to any art style, whether it's pixel art, cel-shading, or high-definition realism, ensuring consistency with your game’s visual aesthetic.",
        activeKey: "4",
    },
    {
        question : "Do you create custom furry character art?",
        content: "Yes, we specialize in custom furry art, including character design, portraits, and full-body illustrations. Every piece is crafted to bring your furry persona to life with unique details and style. ",
        activeKey: "5",
    },
    {
        question : "How detailed is your concept art?",
        content: "We offer both rough concepts and fully detailed designs, depending on your needs. Rough sketches are ideal for brainstorming, while detailed concepts are perfect for final productions.",
        activeKey: "6",
    },
    {
        question : "What kind of environments can you design?",
        content: "We design diverse environments, including landscapes, interiors, cityscapes, forests, and futuristic worlds. These designs can be used for games, animations, or storytelling projects.",
        activeKey: "7",
    },
];


const AccordionTwo = ({customStyle}) => {
    const [activeKey1, setActiveKey1] = useState(null);
    const [activeKey2, setActiveKey2] = useState(null);

    const handleToggle1 = (key) => {
        setActiveKey1(activeKey1 === key ? null : key);
    };
    const handleToggle2 = (key) => {
        setActiveKey2(activeKey2 === key ? null : key);
    };

    return (
        <div className={`rn-accordion-style ${customStyle}`}>
            <Accordion  
                activeKey={activeKey1} 
                onSelect={(key) => handleToggle1(key)}
                >
                {Data1.map((data, index) => (
                    <Card key={index}
                        style={{
                            background: activeKey1 === `${data.activeKey}`
                                ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.9), rgba(227, 63, 161, 0.59))' 
                                : `var(--color-lessdark)`
                        }}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={data.activeKey}>
                                <div> {data.question} </div>
                                <div className="icon"> {activeKey1  === `${data.activeKey}` ? <FaMinus style={{color: `var(--color-primary)`}} /> : <FaPlus />} </div>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={data.activeKey}>
                            <Card.Body> {data.content} </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>

            <Accordion      
                activeKey={activeKey2} 
                onSelect={(key) => handleToggle2(key)}
                >
                {Data.map((data, index) => (
                    <Card key={index}
                        style={{
                            background: activeKey2 === `${data.activeKey}`
                                ? 'linear-gradient(135deg, rgba(30, 30, 30, 0.9), rgba(227, 63, 161, 0.59))' 
                                : `var(--color-lessdark)`
                        }}>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link" eventKey={data.activeKey}>
                                <div> {data.question} </div>
                                <div className='icon'> {activeKey2 === `${data.activeKey}` ? <FaMinus style={{color: `var(--color-primary)`}} /> : <FaPlus />} </div>
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey={data.activeKey}>
                            <Card.Body> {data.content} </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                ))}
            </Accordion>
        </div>
    )
}
export default AccordionTwo

