import React from 'react';
import SectionTitle from '../sectionTitle/SectionTitle';
import PortfolioRelative from './PortfolioRelative';
import TestimonialThree from '../testimonial/TestimonialThree';
import CalltoActionTwo from '../calltoaction/CalltoActionTwo';
import Separator from '../separator/Separator';


const PortfolioDetailsContent = ({ data }) => {
    return (
        <div className="rwt-portfolio-details rn-section-gap">
            <div className="container">
                <div className="row">
                    <div className="col-lg-10 offset-lg-1">
                        <div className="inner">
                            <div className="details-list pb--50">
                                <div className="thumbnail alignwide">
                                    <img className="radius w-100" src={`${process.env.PUBLIC_URL}/${data.largeImage}`} alt={`${data.title} Code Aims`} />
                                </div>

                                <div className="row mt--40 row--30">
                                    <div className="col-lg-6">
                                        <div className="content-left">
                                            <h4 className="title">{data.title}</h4>
                                            <div className="single-list-wrapper">
                                                <div className="single-list">
                                                    <label>Date:</label>
                                                    <span>{data.date}</span>
                                                </div>
                                                <div className="single-list">
                                                    <label>Client:</label>
                                                    <span>{data.client}</span>
                                                </div>
                                                <div className="single-list">
                                                    <label>Category:</label>
                                                    <span>{data.category}</span>
                                                </div>
                                            </div>
   
                                        </div>
                                    </div>
                                    <div className="col-lg-6 mt_md--30 mt_sm--30">
                                        <div className="content-right">
                                            <h4 className="subtitle">{data.subtitle}</h4>
                                            <div className="description" dangerouslySetInnerHTML={{__html: data.body}}></div>
                                        </div>
                                        {data?.btn?.buttontext && (
                                            <div className="view-btn mt--30">
                                                <a
                                                className="btn-default btn-large round"
                                                target="_blank"
                                                rel="noopener noreferrer"
                                                href={data.btn.link}
                                                >
                                                {data.btn.buttontext}
                                                </a>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                            
                            <Separator />

                           {/* Start Elements Area  */}
                            <div className="rwt-elements-area rn-section-gapTop">
                                <div className="container">
                                    <div className="row mb--20">
                                        <div className="col-lg-12">
                                            <SectionTitle
                                                textAlign = "text-center"
                                                radiusRounded = ""
                                                subtitle = "Client Feedback"
                                                title = "Reviews from <strong>Happy Clients.</strong>"
                                            />
                                        </div>
                                    </div>
                                    <TestimonialThree  teamStyle="card-style-default testimonial-style-one" />
                                </div>
                            </div>
                            {/* End Elements Area  */}
                            
                        </div>
                    </div>
                </div>
            </div>

            {/* Start Elements Area  */}
            <div className="rwt-team-area rn-section-gap">
                <div className="wrapper plr--65 plr_sm--15">
                    <div className="row mb--20">
                        <div className="col-lg-12">
                            <SectionTitle
                                textAlign = "text-center"
                                radiusRounded = ""
                                subtitle = "Relative Portfolio"
                                title = "Latest <strong>Portfolio</strong>"
                                description = ""
                            />
                        </div>
                    </div>
                    <PortfolioRelative Column="col-lg-4 col-md-6 mt--30 portfolio"  />
                </div>
            </div>
            {/* End Elements Area  */}

             {/* call action Start */}
            <CalltoActionTwo title={`Your Goals, <strong>Our Mission!</strong>`} subtitle="We help businesses analyze their processes and develop customized solutions that optimize performance and growth."  btnText="Contact Now" />
            {/* call action End */}
        </div>
    )
}

export default PortfolioDetailsContent;
