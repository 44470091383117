import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const ServiceList = [
    {
        image: '/images/service/optimize-your-process.webp',
        title: 'OPTIMIZE YOUR EFFICIENCY!',
        description: 'A personal manager will assist you building strategy, optimizing your budget and deadline, organize the development process and launch the project.'
    },
    {
        image: '/images/service/accelerate-your-revenue.webp',
        title: 'BOOST YOUR REVENUE NOW!',
        description: 'With over 3 years of combined experience, we turn your unique requirements into profitable solutions, no matter the industry or location.'
    },
    {
        image: '/images/service/protect-your-business.webp',
        title: 'PROTECT YOUR BUSINESS FUTURE!',
        description: 'We build secure crafting seamless websites and fully-responsive with the latest open-source technology for optimal performance.'
    },
    {
        image: '/images/service/generation-strategy.webp',
        title: 'START GENERATING LEADS WITH OUR STRATEGY!',
        description: 'Combining tech and marketing, we deliver comprehensive services, including UI/UX design, web development, SEO, and promotion.'
    },
    {
        image: '/images/service/exceed-your-expectation.webp',
        title: 'GO BEYOUND YOUR EXPECTATIONS!',
        description: 'Our work is grounded in honesty, transparency, trust, and adherence to the law.'
    },
    {
        image: '/images/service/respect-you.webp',
        title: 'WE APPRECIATE YOU!',
        description: 'It’s great to work with honesty, transparency, trustworthy and law-abiding.'
    }
]
const ServiceThree = ({textAlign, serviceStyle}) => {
    return (
        <div className="row service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign} cursor-p `}>
                            <div className="inner">
                                <div className="image">
                                    <img src={`${val.image}`} alt={`${val.title} Code Aims`} />
                                </div>
                                <div className="content">
                                    <h3 className="title" dangerouslySetInnerHTML={{__html: val.title}}></h3>
                                    <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                                </div>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceThree;