import React from 'react';
import {Link} from "react-router-dom";
import BlogListData from "../../../data/blog/BlogList.json";
import { BiMessageSquareEdit } from "react-icons/bi";

const SidebarPost = () => {
    return (
        <ul>
            {BlogListData.slice(0, 4).map((value) => {
                return(
                    <li key={value.id}>
                        <div style={{display: 'flex', alignItems: 'center'}}>
                            <Link className="d-block" to={process.env.PUBLIC_URL + `/blogs/${value.slug}`}>
                                {value.title}
                                <BiMessageSquareEdit className='ml--15' style={{fontSize: '24px'}} />
                            </Link>
                        </div>
                        <span className="cate">{value.categories.slice(0, 1)}</span>
                    </li>
                )
            })}
        </ul>
    )
}

export default SidebarPost
