import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route, useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import PageScrollTop from './components/pageToTop/PageScrollTop';

// Import CSS
import './assets/scss/style.scss';

// Import pages and components
import HomePage from './pages/HomePage';
import AboutUs from "./pages/AboutUs";
import PersonalPortfolio from "./pages/PersonalPortfolio";
import Services from './pages/Services';
import Blogs from './pages/Blogs';
import WebDevelopment from "./pages/WebDevelopment";
import Registration from './pages/Registration';
import CategoryList from "./pages/CategoryList";
import TagList from "./pages/TagList";  
import Author from "./pages/Author";
import Portfolio from './pages/Portfolio';
import PortfolioDetails from "./pages/PortfolioDetails";
import BlogDetails from "./pages/BlogDetails";
import Error from "./pages/Error";
import Contact from './pages/Contact';
import CookieConsent from './common/CookieConsent';

const TRACKING_ID = 'G-SH5D8BZ6E1';

const App = () => {
    return (
        <Router>
            <GAWrapper>
                <PageScrollTop>
                    <Switch>
                        <Route path={`${process.env.PUBLIC_URL + "/"}`} exact component={HomePage} />
                        <Route path={`${process.env.PUBLIC_URL + "/about-us"}`} exact component={AboutUs} />
                        <Route path={`${process.env.PUBLIC_URL + "/services"}`} exact component={Services} />
                        <Route path={`${process.env.PUBLIC_URL + "/web-development"}`} exact component={WebDevelopment} />
                        <Route path={`${process.env.PUBLIC_URL + "/contact"}`} exact component={Contact} />
                        <Route path={`${process.env.PUBLIC_URL + "/portfolio"}`} exact component={Portfolio} />
                        <Route path={`${process.env.PUBLIC_URL + "/portfolio/:slug"}`} exact component={PortfolioDetails} />
                        {/* Blog Part */}
                        <Route path={`${process.env.PUBLIC_URL + "/blogs"}`} exact component={Blogs} />
                        <Route path={`${process.env.PUBLIC_URL + "/blogs/:slug"}`} exact component={BlogDetails} />
                        <Route path={`${process.env.PUBLIC_URL + "/category/:slug"}`} exact component={CategoryList} />
                        <Route path={`${process.env.PUBLIC_URL + "/archive/:slug"}`} exact component={Author} />
                        <Route path={`${process.env.PUBLIC_URL + "/tag/:slug"}`} exact component={TagList} />
                        <Route path={`${process.env.PUBLIC_URL + "/registration"}`} exact component={Registration} />
                        <Route path={`${process.env.PUBLIC_URL + "/farhad-developer"}`} exact component={PersonalPortfolio} />
                        <Route path="*" exact component={Error} />
                    </Switch>
                    <CookieConsent />
                </PageScrollTop>
            </GAWrapper>
        </Router>
    );
};

// Separate component to handle Google Analytics initialization and tracking
const GAWrapper = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // Initialize Google Analytics
        ReactGA.initialize(TRACKING_ID);
    }, []);

    useEffect(() => {
        // Track page views on route change
        ReactGA.send({ hitType: 'pageview', page: location.pathname + location.search });
    }, [location]);

    return <>{children}</>;
};

export default App;
