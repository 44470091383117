import React from 'react';
import CountUp from 'react-countup';
import TrackVisibility from "react-on-screen";
import { AiOutlineFundProjectionScreen } from "react-icons/ai";
import { FaUsers } from "react-icons/fa";
import { MdWorkHistory } from "react-icons/md";

const Data = [
    {
        countNum : 210,
        countTitle: 'Project Completed',
        icon: <AiOutlineFundProjectionScreen />,
    },
    {
        countNum : 340,
        countTitle: 'Satisfied Clients',
        icon: <FaUsers />,
    },
    {
        countNum : 3,
        countTitle: 'Years of Experience',
        icon: <MdWorkHistory />,
    },
];

const CounterUpFour = ({textALign, counterStyle, column}) => {
    return (
        <div className="row">
            {Data.map((data, index) => (
                <div className={`${column}`} key={index}>
                    <div className={`count-box ${counterStyle} ${textALign}`}>
                        <div className='count-icon'>{data.icon}</div>
                        <TrackVisibility once>
                            {({ isVisible }) => isVisible && 
                                <div className="count-number">{isVisible ? <CountUp end={data.countNum} /> : 0}</div>}
                        </TrackVisibility>
                        <p className="counter-title">{data.countTitle}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}
export default CounterUpFour;