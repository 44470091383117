import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const tabData = [
    {
        title: "Gardening",
        imgSrc: "./images/portfolio/01/garden.webp",
        tabDescription: "Blog website.",
    },
    {
        title: "Ibrahim Memon",
        imgSrc: "./images/portfolio/01/ibrahim-memon.webp",
        tabDescription: "Passionate software developer.",
    },
    {
        title: "Chabook Services",
        imgSrc: "./images/portfolio/01/chabook.webp",
        tabDescription: "Mobile application website.",
    },
    {
        title: "Rezilla",
        imgSrc: "./images/portfolio/01/rezilla.webp",
        tabDescription: "Real estate website.",
    },
];

const AdvanceService = () => {
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="order-lg-1 order-2 col-lg-8 mt_md--30 mt_sm--30">
                        <div className="advance-tab-content advance-tab-content-1">
                            {tabData.map((data, index) => (
                                <TabPanel key={index}>
                                    <div className="tab-content">
                                        <div className="inner">
                                            <div className="thumbnail mb-5">
                                                <img src={data.imgSrc} alt={data.title} />
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            ))}
                        </div>
                    </div>
                    <div className="order-lg-2 order-1 col-lg-4 ">
                        <div className="advance-tab-button advance-tab-button-1 right-align">
                            <TabList className="tab-button-list">
                                {tabData.map((data, index) => (
                                    <Tab key={index}>
                                        <div className="tab-button">
                                            <h4 className="title">{data.title}</h4>
                                            <p className="description">{data.tabDescription}</p>
                                        </div>   
                                    </Tab>
                                ))}
                            </TabList>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceService;