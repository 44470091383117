import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";
import { FiArrowRight } from 'react-icons/fi';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const SlipThree = ({id, title, description, image, btnText}) => {
    return (
        <div className="rn-splite-style bg-color-blackest" id={id}>
            <div className="split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail">
                            <img src={image} alt={`${title} Code Aims`} />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <h4 className="title">
                                    {title}
                                </h4>
                            </ScrollAnimation>
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>

                                {description}
                                
                                {btnText && (
                                    <div className="button-group mt--30 mt_sm--20">
                                        <Link className="btn-default btn-medium btn-icon btn-border" to="/contact">
                                            {btnText} <i className="icon"><FiArrowRight /></i>
                                        </Link>
                                    </div>
                                )}
                            </ScrollAnimation>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipThree;