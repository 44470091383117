import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const callToActionData = {
    title: "Have a web project in mind? Let's talk! Our team is eager to help turn your vision into reality.",
    btnText: "Get a Query",
}

const CalltoActionSix = () => {
    return (
        <div className="rn-callto-action clltoaction-style-default style-6">
            <div className="container">
                <div className="row row--0 align-items-center content-wrapper">
                    <div className="col-lg-8 col-12">
                        <div className="inner">
                            <div className="content text-start">
                                <h2 className="title reveal-text" data-bg-color="#059bff7a" data-fg-color="white"> 
                                    {callToActionData.title}
                                </h2>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-4 col-12">
                        <div className="call-to-btn text-center text-lg-end mt_md--30 mt_sm--30">
                            <Link className="btn-default" to="/contact">
                                {callToActionData.btnText}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default CalltoActionSix;