import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";


const SlpitOne = () => {
    return (
        <div className="rn-splite-style rn-section-gapBottom">
            <div className="container-fluid split-wrapper">
                <div className="row no-gutters radius-10 align-items-center">
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail image-left-content">
                            <img className='animated' src="./images/clientWorks/about-img.webp" alt="Empowering Businesses With Code Aims" />
                        </div>
                    </div>
                    <div className="col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <h2 className="title">
                                <strong>Empowering Businesses</strong>  to Succeed Online.
                                </h2>
                            </ScrollAnimation>
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <p className="desc-18">
                                We specialize in three core areas: <strong>website development, UX / UI, and SEO</strong>. Our tailored solutions, crafted for both small businesses and global brands, focus on building user-friendly, high-converting websites, enhancing online visibility, and driving targeted traffic.
                            </p>
                            </ScrollAnimation>

                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                            <p className="desc-18">
                                As <strong>Google Partners</strong>, we leverage the latest tools and insights to keep your business ahead, increasing visibility, generating leads, and boosting sales with proven strategies and exceptional results. Let's get growing!
                            </p>
                            </ScrollAnimation>
                            
                            <ScrollAnimation 
                            animateIn="fadeInUp"
                            animateOut="fadeInOut"
                            animateOnce={true}>
                                <ul className="split-list">
                                    <li> <strong>- 3+ years in business</strong></li>
                                    <li>- High Quality Websites</li>
                                    <li> <strong>- Exceptional customer support</strong></li>
                                    <li>- Custom-built e-commerce store</li>
                                    <li> <strong>- Security, made simple </strong></li>
                                    <li>- Best web designers</li>
                                    <li> <strong>- Fast and reliable </strong></li>
                                </ul>
                            </ScrollAnimation>
                        </div>
                        
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SlpitOne
