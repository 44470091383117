import React, { useState } from 'react';

const CustomRadio = ({ id, label, name, checked, onChange }) => (
     <div className="custom-radio">
         <input type="radio" id={id} name={name} checked={checked} onChange={onChange} />
         <label htmlFor={id}>{label}</label>
     </div>
);

const RegistrationNote = () => {
     const [formData, setFormData] = useState({
          FullName: '',
          FatherName: '',
          COURSE: '',
          Gender: '',
          SchoolAttend: '',
          marksObtained: '',
          Email: '',
          Telephone: '',
          Address: '',
          City: ''
     });

     const [statusMessage, setStatusMessage] = useState('');
     const [isError, setIsError] = useState(false);
     const [isSubmitting, setIsSubmitting] = useState(false);

     const scriptURL = 'https://script.google.com/macros/s/AKfycbxRmbRZioW2Z8ppIlf1qJt4Qd4SHxbGItDMDukUHccNIbG6hkRvUEfRdQ6N-nZPE7TxGw/exec';
     
     const handleChange = (e) => {
          const { name, value } = e.target;
          setFormData({
               ...formData,
               [name]: value
          });
     };

     const handleSubmit = async (e) => {
          e.preventDefault();
          setIsSubmitting(true);
          setStatusMessage('');
          setIsError('');

          try {
               const response = await fetch(scriptURL, {
                    method: 'POST',
                    body: new URLSearchParams(formData)
               });

               if (response.ok) {
                    setStatusMessage('Congratulations on registering! Welcome to our academic community!');
                    setFormData({
                         FullName: '',
                         FatherName: '',
                         COURSE: '',
                         Gender: '',
                         SchoolAttend: '',
                         marksObtained: '',
                         Email: '',
                         Telephone: '',
                         Address: '',
                         City: ''
                    });
               } else {
                    throw new Error('Network response was not ok.');
               }
          } catch (error) {
               setStatusMessage('Sorry, something went wrong!');
               console.error('Error!', error.message);
          } finally {
               setIsSubmitting(false);
               setTimeout(() => setStatusMessage(''), 8000);
          }
    };         

    return (
     <div className="form-container">   

          {/* Notice board starts  */}
          <div className="notice-board">
               <h6>Notice Board:</h6>
               <ol>
                    <li> All communication regarding registration process, shortlisting process and class orientation details will be done through email or phone number so please make sure you provide the correct email address and phone number at time of registration.</li>
                    <li> Please check your spam or junk e-mail folder just in case email got delivered there instead of your Inbox.</li>
               </ol>
          </div>
          {/* Notice board ends  */}

          {/* term and conditions starts  */}
          <div className="terms">
               <h6>I agree with all Terms & Conditions that</h6>
               <ul>
                    <li> The Code Aims is not an offer of an employment and it is in no way associated with any future employment opportunity. The Program only aims to provide training to the candidate to enhance their professional capability and employability.</li>
                    <li> Candidates will be required to follow all the rules and regulations of the program including timings and confidentiality requirements.</li>
                    <li> The candidates will be awarded training completion certificates on successfully completing the program. These certificates will be based on formal performance evaluation and rating. Candidates will be evaluated on attendance, student’s performance and other assignments, which may be assigned during the span of course.</li>
                    <li> The duration of the training is 3 months. However, the training of a candidate can be discontinued at any stage without any reason as per requirement of the project</li>
                    <li> The submission of application does not guarantee that your application will be successful for enrollment in the program.</li>
                    <li> Any decision made in the interest of this project is not challengeable in court / judiciary.</li>
                    <li> Classes can be rescheduled at any point in time as per requirement of the project.</li>
                    <li> Any decision regarding training or transfer of the center as per requirement of the project, is not challengeable in any court/judiciary.</li>
                    <li> Candidate must be available during the training hours to attend this training.</li>
               </ul>
          </div>
          {/* term and conditions ends  */}

          {/* form section starts  */}
          <form onSubmit={handleSubmit} className="form-sec">
               {/* personal informatin starts  */}
               <div className="user-info">
                    <div className="nav-user-info row row--0">
                         <div className="head col-lg-4 col-md-4 col-12">
                              Personal Information
                         </div>
                         <div className="note col-lg-8 col-md-8 col-12">
                              Note: Kindly give your own CNIC No/your parents/guardians. Also ensure that the information provided is correct.
                         </div>
                    </div>
                    {/* row  row--15 */}
                    <div className='row row--15'>
                         <div className="form-group col-lg-6 col-md-6 col-12">
                              <div className='form-field ' >
                                   <input type="text" id="name" name="FullName" value={formData.FullName}
                                   onChange={handleChange} required /> 
                                   <label htmlFor="name">Full Name <span>*</span>
                                   </label>
                              </div>
                         </div>
                         <div className='form-group col-lg-6 col-md-6 col-12'>
                              <div className='form-field '>
                                   <input type="text" name="FatherName" id="fname" value={formData.FatherName}
                                   onChange={handleChange} required />
                                   <label htmlFor="fname">Father's Name <span>*</span>
                                   </label>
                              </div>
                         </div>
                         <div className='form-group col-lg-6 col-md-6 col-12'>
                              <div className='form-field '>
                                   <select name="COURSE" id="cour"  value={formData.COURSE}
                                   onChange={handleChange} required>
                                        <option value="--- Please Select ---">--- Please Select ---</option>
                                        <option value="Front End Development">Front-End Development</option>
                                        <option value="HTML5, CSS3, Javascript">HTML5, CSS3, JavaScript</option>
                                        <option value="WordPress (CMS)">WordPress (CMS)</option>
                                   </select>
                                   <label htmlFor="cour">Choose your Course <span>*</span>
                                   </label>
                              </div>
                         </div>
                         <div className=' col-lg-6 col-md-6 col-12'>
                              Gender <span style={{color: 'red'}}>*</span>
                              <div className='form-group1 mb--0'>
                                   <CustomRadio id="male"
                                        label="Male"
                                        name="Gender"
                                        checked={formData.Gender === 'male'}
                                        onChange={() => setFormData({ ...formData, Gender: 'male' })} 
                                   />
                                   <CustomRadio id="female"
                                        label="Female"
                                        name="Gender"
                                        checked={formData.Gender === 'female'}
                                        onChange={() => setFormData({ ...formData, Gender: 'female' })}
                                   />
                                   <CustomRadio id="other"
                                        label="Other"
                                        name="Gender"
                                        checked={formData.Gender === 'other'}
                                        onChange={() => setFormData({ ...formData, Gender: 'other' })}
                                   />
                              </div>
                         </div>  
                    </div>
               </div>
               {/* personal information ends   */}
               {/* academic information starts  */}
               <div className="user-info">
                    <div className="nav-user-info row row--0">
                         <div className="head col-lg-4 col-md-4 col-12">
                              Academic Information
                         </div>
                         <div className="note col-lg-8 col-md-8   col-12">
                              Note: Fill in the details of your latest degree in this section. 
                         </div>
                    </div>
                    <div className='row'>
                         <div className="form-group col-lg-6 col-md-6 col-12">
                              <div className='form-field '>
                                   <input type="text" id="degree" name="SchoolAttend" value={formData.SchoolAttend}
                                   onChange={handleChange} required />
                                   <label htmlFor="degree">
                                        School / College last attended <span>*</span>
                                   </label>
                              </div>
                         </div>
                         <div className='form-group col-lg-6 col-md-6 col-12'>
                              <div className='form-field '>
                                   <input type="text" id="marksObtained" name="marksObtained"  value={formData.marksObtained}
                                   onChange={handleChange} required />
                                   <label htmlFor="marksObtained">
                                        Marks Obtained <span>*</span>
                                   </label>
                              </div>
                         </div>
                    </div>
               </div>
               {/* academic information ends */}
               {/* Contact information starts */}
               <div className="user-info">
                    <div className="nav-user-info row row--0">
                         <div className="head col-lg-4 col-md-4  col-12">
                              Contact Information
                         </div>
                         <div className="note col-lg-8 col-md-8  col-12">
                              Note: All the correspondence (such as login information) will be done via email, so kindly enter your personal active email. Also the mobile number should be your own or your parents/ guardians.
                         </div>
                    </div>
                    <div className='row'>
                         <div className="form-group col-lg-6 col-md-6 col-12">
                              <div className='form-field'>
                                   <input type="email" name="Email" id="email" value={formData.Email}
                                   onChange={handleChange} required />
                                   <label htmlFor="email">Email Address <span>*</span>
                                   </label>
                              </div>
                         </div>
                         <div className='form-group col-lg-6 col-md-6 col-12'>
                              <div className='form-field '>
                                   <input type="tel" name="Telephone" id="mobile" value={formData.Telephone}
                                   onChange={handleChange} required />
                                   <label htmlFor="mobile">
                                        Mobile No. <span>*</span>
                                   </label>
                              </div>
                         </div>
                         <div className='form-group col-lg-6 col-md-6 col-12'>
                              <div className='form-field '>
                                   <input type="text" id="city" className="city" name="City" value={formData.City}
                                   onChange={handleChange} required />
                                   <label htmlFor="city">City <span>*</span></label>
                              </div>
                         </div>
                         <div className='form-group col-lg-6 col-md-6 col-12'>
                              <div className='form-field '>
                                   <input  type='text' name="Address" className='address' id="address" value={formData.Address}
                                   onChange={handleChange} required />
                                   <label htmlFor="address">Address <span>*</span></label>
                              </div>
                         </div>
                    </div>
               </div>
               {/* academic information ends  */}
               <div className="submit-form">
                    <button type="submit" name='submit' className='btn-default btn-large' disabled={isSubmitting}>
                         {isSubmitting ? 'Submitting...' : 'Submit Now'}
                    </button>
                    {statusMessage && ( 
                         <p className={`status-message pt--20 ${isError ? 'error' : 'success'}`}> 
                              {statusMessage}
                         </p>
                    )}
               </div>
          </form>
          {/* form section ends */}
     </div>
    )
}
export default RegistrationNote;
