import React from 'react';
import TrackVisibility from "react-on-screen";
import Skill from "./Skill";

const progressData = [
    {
      id: 1,
      title: "Web Development",
      percantage: 95,
      progressColor: "#059DFF",
    },
    {
      id: 2,
      title: "WordPress Development",
      percantage: 95,
      progressColor: "#059DFF",
    },
    {
      id: 3,
      title: "Custom Development",
      percantage: 95,
      progressColor: "#059DFF",
    },
    {
      id: 4,
      title: "Branding & Logo Design",
      percantage: 85,
      progressColor: "#059DFF",
    },
    {
      id: 5,
      title: "Hosting maintenance & Doamin Purchasing",
      percantage: 90,
      progressColor: "#059DFF",
    },
];

const ProgressbarTwo = () => {
    return (
        <>
            {progressData.map((progress) => (
            <TrackVisibility
                once
                key={progress.id}
                className="single-progress"
            >
                <Skill progress={progress} /> 
            </TrackVisibility>
            ))} 
        </>
    )
}

export default ProgressbarTwo;
