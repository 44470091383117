import React from 'react';
import ScrollAnimation from "react-animate-on-scroll";

const SlipFive = () => {
    return (
        <div className="rn-splite-style rn-section-gapBottom">
            <div className="container-fluid split-wrapper">
                <div className="row no-gutters align-items-center">
                    <div className="order-2 order-lg-1 col-lg-12 col-xl-6 col-12">
                        <div className="split-inner">
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                    <h2 className="title">
                                        <strong>Custom web development:</strong> No templates. No themes. Just hassle-free solutions 
                                    </h2>
                            </ScrollAnimation>
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <p className="desc-18">
                                    At <strong>Code Aims</strong>, we promise never to rely on templates. Every website we create is uniquely designed to align with your business goals and showcase your brand identity. We believe in crafting tailor-made web solutions that truly reflect what makes your business special. <strong>With our custom-built sites, you'll stand out from the competition and deliver a distinctive online experience to your users.</strong>
                                </p>
                            </ScrollAnimation>
                            <ScrollAnimation 
                                animateIn="fadeInUp"
                                animateOut="fadeInOut"
                                animateOnce={true}>
                                <p className="desc-18">
                                    From concept to completion, we make everything seamless and easy for you. Our streamlined design process ensures smooth collaboration, while our user-friendly website management tools empower you to make updates effortlessly. Focus on growing your business while we handle all the technical details, delivering a professional, high-performing website crafted exclusively for your needs. <strong>Choose Code Aims for a bespoke, hassle-free web development experience</strong> that prioritizes your brand and drives measurable results.
                                </p>
                            </ScrollAnimation>
                        </div>
                    </div>
                    <div className="order-1 order-lg-2 col-lg-12 col-xl-6 col-12">
                        <div className="thumbnail image-left-content ">
                            <img className='border-left' src="./images/clientWorks/about-img1.webp" alt="Custom web development - Code Aims" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SlipFive;