import React from 'react';
import Slider from "react-slick";
import { slickPortfolio } from "../../utils/script";

const TestimonialData = [
    {
        form: "German",
        description: "Working with Code Aims was a game-changer for our business. They not only redesigned our outdated website but also custom-developed features that fit our unique business needs perfectly. The new design is visually stunning, responsive, and has improved our conversion rates significantly. Their team was professional, communicative, and delivered the project on time. I highly recommend them for anyone needing custom or WordPress website solutions.",
        name: "Jessica R.",
        subtitle: "Marketing Director, Tech Innovators",
        image: "testimonial-dark-01"
    },
    {
        form: "Poland",
        description: "Code Aims did a fantastic job developing our WordPress site. Their team provided tailored development solutions to meet all our functional needs. The process was smooth, and the final result exceeded our expectations. The redesign also brought a fresh, modern look to our website, improving both user experience and performance. They are experts in WordPress development, and I couldn’t be more satisfied with the results!",
        name: "Mark L.",
        subtitle: "Founder, GreenTech Solutions",
        image: "testimonial-dark-02"
    },
    {
        form: "UK",
        description: "The team at Code Aims delivered an outstanding custom website for us. Our old website was outdated and didn’t reflect our brand properly. They reimagined our entire online presence, offering a modern, user-friendly design. The custom development included everything we needed to enhance functionality, and the WordPress integration made content management easy. We are thrilled with our new site and highly recommend Code Aims for any website redesign or development project.",
        name: "Sarah P.",
        subtitle: "CEO, Luxe Interiors",
        image: "testimonial-dark-03"
    },
]
const TestimonialThree = ({teamStyle}) => {
    return (
        <div className="row">
            <div className="col-lg-12">
                <Slider className="slick-space-gutter--15 rn-slick-dot rn-slick-arrow mb--60" {...slickPortfolio}>
                    {TestimonialData.map((data, index) => (
                        <div key={index} className={`testimonial-style-two ${teamStyle}`}>
                            <div className="row align-items-center row--20">
                                <div className="order-2 order-md-1 col-lg-6 col-md-8 offset-lg-1">
                                    <div className="content mt_sm--40">
                                        <span className="form">{data.form}</span>
                                        <p className="description">{data.description}</p>
                                        <div className="client-info">
                                            <h4 className="title">{data.name}</h4>
                                            <h6 className="subtitle">{data.subtitle}</h6>
                                        </div>
                                    </div>
                                </div>
                                <div className="order-1 order-md-2 col-lg-4 col-md-4">
                                    <div className="thumbnail">
                                        <img className="w-100 h-100" src={`/images/testimonial/${data.image}.jpg`} alt={`${data.name}`} />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    ))}
                </Slider>
            </div>
        </div>
    )
}
export default TestimonialThree;




















