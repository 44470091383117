import React, { useState } from 'react';
import HeaderTopBar from '../common/header/HeaderTopBar';
import { FaSistrix } from "react-icons/fa";
import SEO from "../common/SEO";
import BlogProp from '../components/blog/itemProp/BlogProp';
import SideCategories from '../components/blog/sidebar/SideCategories';
import SidebarPost from '../components/blog/sidebar/SidebarPost';
import SidebarArchive from '../components/blog/sidebar/SidebarArchive';
import HeaderOne from '../common/header/HeaderOne';
import BestLayout from '../common/BestLayout';

import blogData from '../data/blog/BlogList.json';

const Blogs = () => {

    const [searchQuery, setSearchQuery] = useState("");
    const [filteredBlogs, setFilteredBlogs] = useState(blogData);

    const handleInputChange = (e) => {
        const query = e.target.value.trim().toLowerCase();
        setSearchQuery(query);

        if (!query) {
            setFilteredBlogs(blogData);
        } else {
            const filtered = blogData.filter(blog =>
                blog.title.toLowerCase().includes(query) ||
                (blog.body?.join(' ').toLowerCase() ?? "").includes(query)
            );
            setFilteredBlogs(filtered);
        }
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
    };

    return (
        <>
            <SEO title="Latest Blogs" />
            <BestLayout>
                <main className="page-wrapper">
                    <HeaderTopBar HeaderSTyle="header-transparent"/>
                    <HeaderOne btnStyle=" round btn-icon" HeaderSTyle="header-transparent pt--20" />

                    {/* Start Slider Area  */}
                    <div className="slider-area slider-style-1 height-750 bg_image" data-black-overlay="7" style={{backgroundImage: `url(${process.env.PUBLIC_URL}/images/bg/about-banner.webp)`}}>
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="inner pt--80 text-center">
                                        <div>
                                            <h3 className="rn-sub-badge">
                                                <span className="theme-gradient">
                                                    News & Updates
                                                </span>
                                            </h3>
                                        </div>
                                        <h1 className="title display-one">Our Blogs</h1>
                                        <p className="description "> From personal development tips to career advice, this blog offers
                                        a wide rangeof topics that are sure to help everyone grow and succeed.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* End Slider Area  */}

                    <div className="main-content">
                        {/* Start Blog Area  */}
                        <div className="rn-blog-area rn-section-gap">
                            <div className="container">
                                <div className="row row--30">
                                    <div className="col-lg-8">
                                        <div className="row mt_dec--30">
                                            {filteredBlogs.length > 0 ? (
                                                <BlogProp 
                                                    column="col-lg-6 col-md-6 col-12 mt--30" 
                                                    StyleVarProp="your-style-variable" 
                                                    blogs={filteredBlogs}
                                                />
                                            ) : (
                                                <p className="desc-18">No blogs found for the search term "<strong>{searchQuery}</strong>".</p>
                                            )}
                                        </div>
                                    </div>
                                            
                                    <div className="col-lg-4 mt_md--40 mt_sm--40">
                                        <aside className="rwt-sidebar">

                                            <div className="rbt-single-widget widget_search mt--40">
                                                <div className="inner">
                                                    <form className="blog-search" onSubmit={handleFormSubmit}>
                                                        <input type="text" placeholder="Search ..." value={searchQuery} onChange={handleInputChange} />
                                                        <button className="search-button"><FaSistrix /></button>
                                                    </form>
                                                </div>
                                            </div>

                                            {/* Start Single Widget  */}
                                            <div className="rbt-single-widget widget_categories mt--40">
                                                <h3 className="title">Categories</h3>
                                                <div className="inner">
                                                    <SideCategories />
                                                </div>
                                            </div>
                                            {/* End Single Widget  */}

                                            {/* Start Single Widget  */}
                                            <div className="rbt-single-widget widget_recent_entries mt--40">
                                                <h3 className="title">Recent Posts</h3>
                                                <div className="inner">
                                                    <SidebarPost />
                                                </div>
                                            </div>
                                            {/* End Single Widget  */}

                                            {/* Start Single Widget  */}
                                            <div className="rbt-single-widget widget_archive mt--40">
                                                <h3 className="title">Archives</h3>
                                                <div className="inner">
                                                    <SidebarArchive />
                                                </div>
                                            </div>
                                            {/* End Single Widget  */}

                                            {/* Start Single Widget  */}
                                            {/* <div className="rbt-single-widget widget_tag_cloud mt--40">
                                                <h3 className="title">Tags</h3>
                                                <div className="inner mt--20">
                                                    <SidebarTag />
                                                </div>
                                            </div> */}
                                            {/* End Single Widget  */}

                                        </aside>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* End Blog Area  */}
                    </div>

                </main>
            </BestLayout>
        </>
    )
}

export default Blogs
