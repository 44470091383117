import React from 'react';

const BrandList = [
    {
        image: './images/brand/brand-01.png',
        title: '',
        link: '',
    },
    {
        image: './images/brand/brand-02.png',
        title: '',
        link: '',
    },
    {
        image: './images/brand/brand-03.png',
        title: '',
        link: '',
    },
    {
        image: './images/brand/brand-04.png',
        title: '',
        link: '',
    },

]

const BrandFour = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index}>
                    <a href={data.link}><img src={`${data.image}`} alt={data.title} /></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandFour;
